<template>
  <div class="con-wrap">
    <CarrotTitle title="통화 관리 시스템(Call Managemnet System)">
      - 통화관리 시스템은 재직중인 사원들에 대하여 30초 통화에 대한 통계를 보여줍니다.<br>
      - 내선전화 간의 통화는 건수에서 제외됩니다.<br>
      - 기본 검색 기간은 오늘 1일간 입니다.<br>
    </CarrotTitle>
    <div class="board">
      <div class="mt-40">
        <div>
          <table class="table-row table-serach">
            <tbody>
            <tr>
              <th width="180">기간</th>
              <td>
                <CarrotDatePicker v-model="hq.sdate" class="w-110px float-left"></CarrotDatePicker>
                <span class="float-left ml-10 mr-10">~</span>
                <CarrotDatePicker v-model="hq.edate" class="w-110px float-left"></CarrotDatePicker>
              </td>
            </tr>
            <tr>
              <th width="180">검색</th>
              <td>
                <select class="w-200px" v-model="hq.state">
                  <option value="mp3code">내선번호</option>
                  <option value="hq_name">이름</option>
                </select>
                <input type="text" class="w-200px" v-model="hq.search">
              </td>
            </tr>
            </tbody>
          </table>
          <button class="btn-default float-right mt-10" @click="hq.doSearch">검색</button>
          <div class="clear"></div>

          <div class="mt-40 mb-20">
            <CarrotSector v-model="hq.idx_sector"  @change="hq.doSearchOffice" class="w-200px"></CarrotSector>
            <CarrotDept  v-model:idx_sector.sync="hq.idx_sector"  v-model="hq.idx_office" @change="hq.doSearchTeam" class="w-200px"></CarrotDept>
            <CarrotTeam :idx_sector="hq.idx_sector" :idx_office="hq.idx_office" v-model="hq.idx_team" @change="hq.doSearch" class="w-200px ml-5"></CarrotTeam>
            <button class="btn-default float-right" @click="hq.downExcel">Excel 다운로드</button>
            <div class="clear"></div>
          </div>

          <table class="table-col">
            <colgroup>
            </colgroup>
            <thead>
            <tr>
              <th width="60">No</th>
              <th width="80">내선번호
              </th>
              <th width="120">이름
                <span style="cursor:pointer" v-if="hq.orderby!='nameasc' && hq.orderby!='namedesc'" @click="hq.doSearchOrderBy('nameasc')" class="fas fa-arrow-up"></span>
                <span style="cursor:pointer" v-if="hq.orderby!='nameasc' && hq.orderby!='namedesc'"  @click="hq.doSearchOrderBy('nameasc')"  class="fas fa-arrow-down"></span>
                <span style="cursor:pointer" v-if="hq.orderby=='nameasc' " @click="hq.doSearchOrderBy('namedesc')" class="fas fa-arrow-up"></span>
                <span style="cursor:pointer" v-if="hq.orderby=='namedesc'"  @click="hq.doSearchOrderBy('nameasc')"  class="fas fa-arrow-down"></span>
              </th>
              <th>팀
                <span style="cursor:pointer" v-if="hq.orderby!='teamasc' && hq.orderby!='teamdesc'" @click="hq.doSearchOrderBy('teamasc')" class="fas fa-arrow-up"></span>
                <span style="cursor:pointer" v-if="hq.orderby!='teamasc' && hq.orderby!='teamdesc'"  @click="hq.doSearchOrderBy('teamasc')"  class="fas fa-arrow-down"></span>
                <span style="cursor:pointer" v-if="hq.orderby=='teamasc' " @click="hq.doSearchOrderBy('teamdesc')" class="fas fa-arrow-up"></span>
                <span style="cursor:pointer" v-if="hq.orderby=='teamdesc'"  @click="hq.doSearchOrderBy('teamasc')"  class="fas fa-arrow-down"></span>
              </th>
              <th>본부
                <span style="cursor:pointer" v-if="hq.orderby!='officeasc' && hq.orderby!='officedesc'" @click="hq.doSearchOrderBy('officeasc')" class="fas fa-arrow-up"></span>
                <span style="cursor:pointer" v-if="hq.orderby!='officeasc' && hq.orderby!='officedesc'"  @click="hq.doSearchOrderBy('officeasc')"  class="fas fa-arrow-down"></span>
                <span style="cursor:pointer" v-if="hq.orderby=='officeasc' " @click="hq.doSearchOrderBy('officedesc')" class="fas fa-arrow-up"></span>
                <span style="cursor:pointer" v-if="hq.orderby=='officedesc'"  @click="hq.doSearchOrderBy('officeasc')"  class="fas fa-arrow-down"></span>
              </th>
              <th>부문
                <span style="cursor:pointer" v-if="hq.orderby!='sectorasc' && hq.orderby!='sectordesc'" @click="hq.doSearchOrderBy('sectorasc')" class="fas fa-arrow-up"></span>
                <span style="cursor:pointer" v-if="hq.orderby!='sectorasc' && hq.orderby!='sectordesc'"  @click="hq.doSearchOrderBy('sectorasc')"  class="fas fa-arrow-down"></span>
                <span style="cursor:pointer" v-if="hq.orderby=='sectorasc' " @click="hq.doSearchOrderBy('sectordesc')" class="fas fa-arrow-up"></span>
                <span style="cursor:pointer" v-if="hq.orderby=='sectordesc'"  @click="hq.doSearchOrderBy('sectorasc')"  class="fas fa-arrow-down"></span>
              </th>
              <th>발신<br>통화시간</th>
              <th width="80">발신<br>통화건수</th>
              <th>평균발신<br>통화시간</th>
              <th>수신<br>통화시간</th>
              <th width="80">수신<br>통화건수</th>
              <th>평균수신<br>통화시간</th>
              <th>총통화시간</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(irow, i) in hq.list" :key="i">
              <td>{{ irow.num }}</td>
              <td>{{ irow.mp3code }}</td>
              <td class="txt-ellipsis" :title="`${ irow.ename }(${ irow.kname })`"><router-link :to="{ name : 'myPIMS-EicnView-idx', params : { idx:irow.idx }}" target="_blank"><span class="btn-view">{{ irow.ename }}({{ irow.kname }})</span></router-link></td>
              <td class="txt-ellipsis" :title="irow.team_name">{{ irow.team_name }}</td>
              <td class="txt-ellipsis" :title="irow.office_name">{{ irow.office_name }}</td>
              <td class="txt-ellipsis" :title="irow.sector_name">{{ irow.sector_name }}</td>
              <td class="txt-ellipsis" :title="irow.duration_send">{{ irow.duration_send }}</td>
              <td class="txt-ellipsis" :title="irow.cnt_send">{{ irow.cnt_send }}</td>
              <td class="txt-ellipsis" :title="irow.mod_send">{{ irow.mod_send }}</td>
              <td class="txt-ellipsis" :title="irow.duration_recv">{{ irow.duration_recv }}</td>
              <td class="txt-ellipsis" :title="irow.cnt_recv">{{ irow.cnt_recv }}</td>
              <td class="txt-ellipsis" :title="irow.mod_recv">{{ irow.mod_recv }}</td>
              <td class="txt-ellipsis" :title="irow.total_duration">{{ irow.total_duration }}</td>
            </tr>
            <tr v-if="hq.total==0">
              <td colspan="13">정보가 없습니다.</td>
            </tr>
            </tbody>
          </table>
          <CarrotPaging :total="hq.total" :list_per_page="hq.rows" v-model="hq.page" @change="hq.doSearch"></CarrotPaging>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'

import CarrotSector from '@/components/common/CarrotSector.vue'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'
import { useStore } from 'vuex';
export default {
  layout:"myPIMS",

  components: {
    CarrotDatePicker,
    CarrotDept,
    CarrotTeam,
    CarrotSector
  },
  setup() {
    const router = new useRouter();
    const store  = useStore();

    const hq = reactive({
      isAuth : false,
      page   : 1,
      rows   : 20,
      sdate  : "",
      edate  : "",
      phone  : "",
      search : "",
      state  : "mp3code",
      idx_sector : 0,
      idx_office : 0,
      idx_team   : 0,
      join_sort:"",
      orderby:"",

      analysis : [],
      list : [], total : 0,
      doSearchOffice : () => {
        hq.doSearch();
      },
      doSearchTeam : () => {
        hq.doSearch();
      },
      doSearchJoinday : () => {

        if(hq.orderby=='joindayasc')
          hq.orderby='joindaydesc'
        else
          hq.orderby='joindayasc'
        hq.doSearch();
      },
      doSearchOrderBy : (orderby) => {
        hq.orderby=orderby;
        hq.doSearch();
      },
      downExcel : () => {
        // 로컬 테스트 시 local.intranet.mxm.kr,https://api.carrotians.net
        let url = "https://api.carrotians.net" + "/excel/eicn_list"
            + "?sdate="  + hq.sdate
            + "&edate="  + hq.edate
            + "&phone="  + hq.phone
            + "&search=" + hq.search
            + "&idx_sector=" + hq.idx_sector
            + "&idx_office=" + hq.idx_office
            + "&idx_team="   + hq.idx_team
            + "&state="  + hq.state
            + "&join_sort="  + hq.join_sort
        + "&orderby="  + hq.orderby;
        window.open(url, '_blank').focus();
      },

      viewHQ : (idx) => {
        router.push({
          name   : 'myPIMS-PersonalInfoList_tab1-idx',
          params : { idx:idx }
        });
      },


      doSearch : () => {
        let params = {
          page   : hq.page,
          rows   : hq.rows,
          sdate  : hq.sdate,
          edate  : hq.edate,
          phone  : hq.phone,
          search : hq.search,
          idx_sector : hq.idx_sector,
          idx_office : hq.idx_office,
          idx_team   : hq.idx_team,
          state  : hq.state,
          join_sort :hq.join_sort,
          orderby :hq.orderby
        };

        axios.get('/rest/mypims/eicnList', { params : params }).then((res) => {
          if( res.data.err == 0 ){
            hq.list   = res.data.list;
            hq.total  = res.data.total;
          } else {
            console.info(res.data.err_msg);
          }
        });
      }
    });

    onMounted(() => {
      // Mounted
      // console.log(store.state.pd_auth, store.state.position );
      // 현재는 팀 구성이 되어있지 않으므로 스탭을 지정하여 체크하도록 변경, 3995:슈리, 19054:헤이즐

      let td = new Date();
      let nd = new Date(td.getFullYear(), td.getMonth(), td.getDate());
      hq.sdate= [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');
      hq.edate= [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');
      if( store.state.isCmsAdmin == false  ) {
        //if( store.state.pd_auth != true && store.state.position != '리드' ) {
        router.go(-1);
        Swal.fire({
          title : '통화관리시스템',
          text  : '접근 권한이 없습니다.'
        });
        return;
      }

      if( store.state.pd_auth == true ) {
        hq.isAuth = true;
      }

      hq.doSearch();
    });

    onUnmounted(() => {
      // UnMounted
    });

    return {hq};
  }
}
</script>

<style lang="scss" scoped>
</style>